import AdjustIcon from '@mui/icons-material/Adjust';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useEffect, useState } from 'react';

export const ImageComponent = ({
  isLocalized,
  index,
  imageUri,
  selection,
  setSelection,
  handleZoomImage,
  layoutWithPannel
}: any) => {
  const { REACT_APP_BASEURL_MEDIAS } = process.env;
  const [orientation, setOrientation] = useState(null);
  const [hover, setHover] = useState(false);
  const imageOrientation = (src) => {
    const img = new Image();
    img.src = src;
    img.onload = function (e) {
      let orientation = '';
      const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
      console.log(naturalWidth, naturalHeight);
      if (naturalWidth > naturalHeight) {
        orientation = 'landscape';
      } else if (naturalWidth < naturalHeight) {
        orientation = 'portrait';
      } else {
        orientation = 'even';
      }
      setOrientation(orientation);
    };
  };

  useEffect(() => {
    imageOrientation(`${REACT_APP_BASEURL_MEDIAS}${imageUri}`);
  }, []);

  useEffect(() => {
    console.log(orientation);
  }, [orientation]);

  return (
    <>
      <div
        id={isLocalized ? `${index.indexEquipment}-${index.indexTag}` : index}
        style={{
          borderWidth: '5px',
          borderColor:
            selection.selectedImage === index.indexTag && selection.selectEquipment === index.indexEquipment
              ? 'rgb(6, 39, 70)'
              : '#FFFFFF',

          marginBottom: '10px',
          boxSizing: 'border-box',
          borderStyle: 'solid'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {orientation !== null && (
          <div
            style={{
              backgroundImage: `url(${REACT_APP_BASEURL_MEDIAS}${imageUri})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: window.innerWidth < 880 ? '150px' : '250px',
              position: 'relative',
              cursor: 'pointer',
              paddingBottom: '10px',
              borderWidth: '3px',
              boxSizing: 'border-box',
              borderColor:
                selection.selectedImage === index.indexTag && selection.selectEquipment === index.indexEquipment
                  ? '#FFFFFF'
                  : !hover
                    ? '#FFFFFF'
                    : 'rgb(241, 118, 51)',
              borderStyle: 'solid'
            }}
            onClick={
              isLocalized
                ? () => {
                    setSelection((prev) => ({
                      ...prev,
                      selectedImage: index.indexTag,
                      selectEquipment: index.indexEquipment
                    }));
                  }
                : () => {
                    handleZoomImage(`${REACT_APP_BASEURL_MEDIAS}${imageUri}`, orientation);
                  }
            }
          >
            {isLocalized && (
              <div style={{ position: 'absolute', color: 'rgb(6, 39, 70)', scale: '1', padding: '4px' }}>
                <AdjustIcon sx={{ backgroundColor: 'white', borderRadius: '50%' }}></AdjustIcon>
              </div>
            )}
            <div
              style={{
                position: 'relative',
                top: 'calc(100% - 28px)',
                right: '0px',
                color: 'rgb(6, 39, 70)'
              }}
            >
              <ZoomInIcon
                sx={{
                  scale: '.7',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '4px',
                  border: '2px solid rgb(6, 39, 70)'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoomImage(`${REACT_APP_BASEURL_MEDIAS}${imageUri}`, orientation);
                }}
              ></ZoomInIcon>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
