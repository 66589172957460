import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postEvent, submitTags, submitFile, SendMailToClient, SendMailToGest } from '../../../api';
// import { useFormik } from "formik";
import HorizontalStepper from '../blocs/HorizontalStepper';
import { UploadFile } from '../blocs/UploadFile';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Grid } from '@mui/material';

export const Document = ({
  getFile,
  getTags,
  setTags,
  getIdEvent,
  setFile,
  getEventPayload,
  getOrganization,
  getProductInfos,
  getEmailHandlers
}) => {
  let navigate = useNavigate();
  let file = null;

  const { idProduct } = useParams();
  const [open, setOpen] = React.useState(false);
  const [organization, setOrganization] = useState(null);

  const setSelectedFile = (selectedFile) => {
    file = selectedFile;
  };

  useEffect(() => {
    setOrganization(getOrganization);
  }, [getOrganization]);

  useEffect(() => {
    if (getProductInfos === null) navigate('/declareEvent/' + idProduct);
  }, []);

  const sendEmails = (eventData, hasTags) => {
    SendMailToClient(eventData).then(() => {
      SendMailToGest(eventData, getEmailHandlers, hasTags).then(() => {
        setOpen(false);
        navigate(`/validateEvent/${idProduct}`);
      });
    });
  };

  const onCLick = (e) => {
    e.preventDefault();
    if (file) setFile(file);

    setOpen(true);

    postEvent(getEventPayload()).then((eventData) => {
      eventData.address = getProductInfos.address;

      if (getProductInfos.matterport_scan !== undefined && getTags().length > 0) {
        const tags = JSON.parse(JSON.stringify(getTags()));
        tags.forEach((tag) => {
          tag.uuid_organization = getOrganization().uuid;
          tag.uuid_owner = eventData.uuid;
        });
        submitTags(tags).then((tags) => {
          if (file) {
            const body = new FormData();
            body.append('file', file);
            body.append('uuid_organization', getOrganization().uuid);
            body.append('uuid_owner', eventData.uuid);
            body.append('owner_kind', 'event');

            submitFile(body).then((resp) => {
              sendEmails(eventData, getTags().length > 0);
            });
          } else {
            sendEmails(eventData, getTags().length > 0);
          }
        });
      } else {
        if (file) {
          const body = new FormData();
          body.append('file', file);
          body.append('uuid_organization', getOrganization().uuid);
          body.append('uuid_owner', eventData.uuid);
          body.append('owner_kind', 'event');

          submitFile(body).then((resp) => {
            sendEmails(eventData);
          });
        } else {
          sendEmails(eventData);
        }
      }
    });
  };

  const Layout = () => {
    return (
      <div>
        {organization && organization.logo && (
          <img
            className="center"
            src={`${document.location.origin}/img/logo_${organization.logo}.png`}
            alt={organization.name}
            width={150}
          />
        )}

        {organization && !organization.logo && <span className="center">{organization.name}</span>}
      </div>
    );
  };

  if (getProductInfos === null) {
    return;
  }

  return (
    <div>
      <Layout />

      <Grid container spacing={2} sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Grid item xs={12} sm={12}>
          <HorizontalStepper
            step={
              getProductInfos.matterport_scan !== undefined &&
              getProductInfos.matterport_scan.model_id !== undefined &&
              getProductInfos.matterport_scan.model_id !== ''
                ? 2
                : 1
            }
            hasMatterPortScan={getProductInfos.matterport_scan}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <UploadFile setFile={setSelectedFile} />
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={onCLick}>
            Terminer et valider
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
