import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import 'reflect-metadata';
import App from './app/App';

const container = document.getElementById('root');
const root = createRoot(container!);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: ['localhost', process.env.REACT_APP_API_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(<App />);
