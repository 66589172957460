import axios from 'axios';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export const axiosClientRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export async function axiosGet(arg, urlParams = null) {
  return await axiosClient
    .get(arg)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPost(arg, data, urlParams = null) {
  return await axiosClient
    .post(arg, data, urlParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosRefreshPost(arg, data, urlParams = null) {
  return await axiosClientRefresh
    .post(arg, data, urlParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPostMultipart(arg, data) {
  return await axiosClient
    .post(arg, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPostWithoutData(arg) {
  return await axiosClient
    .post(arg)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosDelete(arg) {
  return await axiosClient
    .delete(arg)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPatch(arg, data) {
  return await axiosClient
    .patch(arg, data)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export default axiosClient;
