import {
  createUser,
  patchUser,
  getUsers,
  getUser,
  deleteUser,
  loginUser,
  forgotPassword,
  registerAccount,
  assignProductToUser,
  unAssignProductToUser,
  updatePassword,
  refreshToken,
  deleteAccount,
  getSession
} from './user';
import { getAllContacts, createContact, getContact, updateContact, deleteContact } from '~/api/contact';
import { getEquipment, patchEquipment, deleteEquipment, addEquipment } from '~/api/equipment';
import {
  addEvent,
  getEvent,
  getEvents,
  patchEvent,
  deleteEvent,
  postMessage,
  deleteMessage,
  assignEquipmentToEvent,
  unassignEquipmentToEvent
} from '~/api/event';

import {
  createFile,
  updateFile,
  deleteFile,
  getFile,
  getAllFiles,
  zipFiles,
  createFileContactLink,
  deleteFileContactLink,
  getFileContacts,
  createFileEquipmentLink,
  deleteFileEquipmentLink,
  getFileEquipments
} from '~/api/file';

import {
  getOrganizations,
  getOrganization,
  deleteOrganization,
  patchOrganization,
  addOrganization,
  getContactsOrganization,
  addLogoOrganization
} from '~/api/organization';

import {
  getProducts,
  // getProduct,
  getProductShareLink,
  deleteProduct,
  patchProduct,
  addProduct,
  getProductImages,
  getProductMatterportImages,
  getDocuments,
  activateMatterportScan,
  unactivateMatterportScan,
  getProductEquipments,
  getProductContacts,
  createProductContact,
  assignContact,
  unassignContact,
  setThumbnail,
  createProductContactLink,
  deleteProductContactLink
} from '~/api/product';

import { generateLink, generateQrCode } from '~/api/link';

import { getPageTickets, getPageTicketsDetail } from '~/api/pages';

import { addTag, getTag, patchTag, deleteTag } from '~/api/tag';

import {
  addAddress,
  getAddress,
  patchAddress,
  deleteAddress,
  getAllAddress,
  getBuildingsSameAdress
} from '~/api/address';

import {
  createBuilding,
  getBuilding,
  getAllBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildingEvents,
  getBuildingImages,
  getBuildingDocuments,
  getBuildingAddresse,
  getBuildingEquipments,
  getBuildingProducts,
  createBuildingContact,
  getBuildingContacts,
  createBuildingContactLink,
  deleteBuildingContactLink,
  getBuildingMatterportImages
} from '~/api/building';

import { getDpe, getDpeList } from '~/api/ademe';

import {
  addMatterportScan,
  getMatterportScan,
  patchMatterportScan,
  deleteMatterportScan,
  getMatterportStats
} from '~/api/matterportScan';

import { referencial, equipmentreferencial } from '~/api/referencial';

import {
  createMissionOrder,
  searchMissionOrder,
  getTechnicians,
  getPhotographs,
  assignMissionOrder,
  updateMissionOrder,
  odmLink,
  getEdl,
  patchEDL,
  createRoom,
  createEquipment,
  createEquipmentState,
  attachFileToState,
  createEvent,
  createShortLink,
  updateRoom,
  attachFileToTag,
  edlDone,
  edl3dDone
} from '~/api/missionOrder';
import axiosClient from './axiosClient';

import { axiosGet, axiosPost } from './axiosClient';

export const api = {
  ademe: { get: getDpe, getList: getDpeList },
  address: {
    create: addAddress,
    update: patchAddress,
    get: getAddress,
    delete: deleteAddress,
    search: getAllAddress,
    buildingsProducts: getBuildingsSameAdress
  },
  building: {
    create: createBuilding,
    update: updateBuilding,
    get: getBuilding,
    delete: deleteBuilding,
    search: getAllBuilding,
    // other
    getEvents: getBuildingEvents,
    getImages: getBuildingImages,
    getDocuments: getBuildingDocuments,
    getAddress: getBuildingAddresse,
    getEquipments: getBuildingEquipments,
    getProducts: getBuildingProducts,
    getBuildingsSameAdress: getBuildingsSameAdress,
    getContacts: getBuildingContacts,
    // contacts
    contact: {
      create: createBuildingContact,
      get: getBuildingContacts,
      linkTo: createBuildingContactLink,
      unlinkTo: deleteBuildingContactLink
    },
    matterport: {
      getImages: getBuildingMatterportImages
    }
  },
  contacts: {
    // crud
    create: createContact,
    update: updateContact,
    get: getContact,
    delete: deleteContact,
    search: getAllContacts
  },
  equipments: {
    // crud
    create: addEquipment,
    update: patchEquipment,
    get: getEquipment,
    delete: deleteEquipment
    //search: ?
  },
  events: {
    // crud
    create: addEvent,
    update: patchEvent,
    get: getEvent,
    delete: deleteEvent,
    search: getEvents,
    // message
    message: {
      create: postMessage,
      delete: deleteMessage
    },
    // equipment
    equipment: {
      assign: assignEquipmentToEvent,
      unassign: unassignEquipmentToEvent
    }
  },
  files: {
    // crud
    create: createFile,
    update: updateFile,
    get: getFile,
    delete: deleteFile,
    search: getAllFiles,
    // other
    zip: zipFiles,
    contactLink: {
      create: createFileContactLink,
      delete: deleteFileContactLink,
      get: getFileContacts
    },
    equipmentLink: {
      create: createFileEquipmentLink,
      delete: deleteFileEquipmentLink,
      get: getFileEquipments
    }
  },
  link: {
    // crud
    create: generateLink,
    createQrCode: generateQrCode
  },
  matterport: {
    create: addMatterportScan,
    update: patchMatterportScan,
    get: getMatterportScan,
    delete: deleteMatterportScan,
    stats: getMatterportStats
  },
  organizations: {
    // crud
    create: addOrganization,
    update: patchOrganization,
    get: getOrganization,
    delete: deleteOrganization,
    search: getOrganizations,
    addLogo: addLogoOrganization
    // allready in contact
    // contact: {
    //   create: addContact,
    //   search: getContacts
    //   // getContacts: getContactsOrganization ?
    // }
  },
  pages: {
    ticket: {
      get: getPageTickets,
      getDetail: getPageTicketsDetail
    }
  },
  product: {
    create: addProduct,
    update: patchProduct,
    // get: getProduct,
    delete: deleteProduct,
    search: getProducts,
    // other
    getEvents: getBuildingEvents,
    getImages: getProductImages,
    getDocuments: getDocuments,
    // getAddress: getBuildingAddresse,
    getEquipments: getProductEquipments,
    // getProducts: getBuildingProducts,
    // getBuildingsSameAdress: getBuildingsSameAdress,
    getContacts: createProductContact,
    contact: {
      create: createProductContact,
      get: getProductContacts,
      assign: assignContact, // deprecated
      unassign: unassignContact, // deprecated
      linkTo: createProductContactLink,
      unlinkTo: deleteProductContactLink
    },
    equipments: {
      get: getProductEquipments
    },
    // files
    files: {
      getImages: getProductImages,
      getDocuments: getDocuments,
      setThumbnail: setThumbnail
    },
    // matterport
    matterport: {
      getImages: getProductMatterportImages
    },
    link: {
      // crud
      getShareLink: getProductShareLink
    }
  },
  referencial: {
    get: referencial,
    equipments: {
      get: equipmentreferencial
    }
  },
  tags: {
    create: addTag,
    get: getTag,
    update: patchTag,
    delete: deleteTag
  },
  users: {
    // crud
    create: createUser,
    update: patchUser,
    get: getUser,
    delete: deleteUser,
    search: getUsers,
    deleteAccount: deleteAccount,
    // product
    product: {
      assign: assignProductToUser,
      unAssign: unAssignProductToUser
    },
    //other
    login: loginUser,
    refresh: refreshToken,
    updatePassword: updatePassword,
    forgotPassword: forgotPassword,
    registerAccount: registerAccount,
    getSession: getSession
  },
  missionOrders: {
    create: createMissionOrder,
    update: updateMissionOrder,
    search: searchMissionOrder,
    getTechnicians: getTechnicians,
    getPhotographs: getPhotographs,
    assign: assignMissionOrder,
    odmLink: odmLink,
    edl: {
      update: patchEDL,
      createRoom: createRoom,
      createEquipment: createEquipment,
      createEquipmentState: createEquipmentState,
      attachFileToState: attachFileToState,
      createEvent: createEvent,
      createShortLink: createShortLink,
      edlDone: edlDone,
      edl3dDone: edl3dDone,
      getEdl: getEdl,
      updateRoom: updateRoom,
      updateFileToTag: attachFileToTag
    }
  },
  edl: {
    get: getEdl
  }
};

// Product

// Event

// export async function getEvent(id) {
//   return axiosClient.get(`events/${id}`);
// }

export async function postEvent(data) {
  return axiosPost(`events`, data);
}

export async function submitTags(data) {
  return axiosPost(`tags`, data);
}

export async function submitTag(data) {
  return axiosPost(`tag`, data);
}

export async function submitFile(file) {
  return axiosPost(`files`, file);
}

// Referencial

// export const getReferencial = () => {
//   // return axiosClient.get(`/referencial/v2`);
//   return api.referencial.get();
// };

// EDL

// export async function getEdl(id) {
//   return axiosClient.get(`public/edls/${id}`);
// }

// Organisation

// export async function getOrganization(id) {
//   return axiosClient.get(`organizations/${id}`);
// }

export const referentielTypes = ['', 'Dégât des eaux', 'Panne équipement', 'Autres ...'];

// Emails

export async function SendMailToClient(data) {
  console.log('envoi mail Client', data);
  let body = 'Bonjour,\n\n';
  body +=
    'Nous avons bien reçu votre déclaration d’incident au ' +
    data.address.street +
    ' ' +
    data.address.zip_code +
    ' ' +
    data.address.city +
    ' dont le contenu est le suivant : \n\n';
  body += "Type d'incident : " + referentielTypes[data.kind] + '\n';
  body += 'Description : ' + data.desc + '\n\n';
  body += 'Nous vous prions de croire que nous mettons tout en œuvre pour y répondre dans les meilleurs délais.\n\n';
  body += 'Dans l’attente\n\n';
  body += 'Bien à vous\n\n';
  body += 'Toute l’équipe Gestion d’incidents\n';

  const payloadClient = {
    subject: "Déclaration d'incident au " + data.address.street + ' ' + data.address.zip_code + ' ' + data.address.city,
    body: body,
    data: data,
    to: data.data.contact_requestor.email
  };

  return axiosClient
    .post('/mails', payloadClient)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function SendMailToGest(data, emailsList, hasTags) {
  console.log(data);
  const d = new Date();

  const emails = [];

  console.log(emailsList);

  emailsList.forEach((email) => {
    emails.push(email);
  });

  if (process.env.REACT_APP_IS_PROD) emails.push('manreis@gmail.com', 'e.aucouturier@auditvirtuel.com');

  console.log(emails.toString());

  const datestring =
    d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes();
  let body = 'Bonjour,\n\n';

  const productPageLink =
    process.env.REACT_APP_FONT + data.uuid_owner.split('-')[0] === 'pro'
      ? 'product/' + data.uuid_owner + '\n'
      : 'building/' + data.uuid_owner + '\n';

  console.log(productPageLink);

  body +=
    'Vous avez un nouvel incident à gérer au ' +
    data.address.street +
    ' ' +
    data.address.zip_code +
    ' ' +
    data.address.city +
    ', dont voici les détails :\n\n';

  body += 'Date de signalement : ' + datestring + '\n';
  body += 'Nom : ' + data.data.contact_requestor.name + '\n';
  body += 'Email : ' + data.data.contact_requestor.email + '\n';
  body += 'Téléphone : ' + data.data.contact_requestor.phone + '\n';
  body += "Type d'incident : " + referentielTypes[data.kind] + '\n';
  body += 'Description : ' + data.desc + '\n';
  body += 'Lien vers la page produit : ' + productPageLink;

  if (hasTags) body += 'Lien : ' + process.env.REACT_APP_URL + 'viewEvent/' + data.uuid + '\n\n';
  body += 'Bonne chance\n';
  body += 'Audit Virtuel\n';

  const payloadGestion = {
    subject:
      'Vous avez une nouvelle déclaration d’incident au ' +
      data.address.street +
      ' ' +
      data.address.zip_code +
      ' ' +
      data.address.city,
    body: body,
    data: data,
    to: emails.toString()
  };
  return axiosClient
    .post('/mails', payloadGestion)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}
